import React, { useState, useEffect, useContext } from "react";
import Cookies from 'js-cookie';
import NorthstarPageLayout from "../NorthstarPageLayout";
import HelpButtonContext from "../../contexts/helpContext";
import helpButtonText from "../../contexts/helpButtonText";
import northstarClient from "../../services/northstarClient";

const TableauView: React.FC = () => {
    const [jwt, setJwt] = useState("")
    const [viewUrl, setViewUrl] = useState("")
    const [viewName, setViewName] = useState("")
    const [workbookName, setWorkbookName] = useState("")
    const {updateHelpContext} = useContext(HelpButtonContext);
    const GATSBY_API_URL = process.env.GATSBY_API_URL;

    useEffect(() => {
        updateHelpContext(helpButtonText.gtmModule);
        const queryParams = new URLSearchParams(window.location.search)

        const viewContentUrl = decodeURIComponent(queryParams.get("viewContentUrl") || "");
        const workbookContentUrl = decodeURIComponent(queryParams.get("workbookContentUrl") || "");
        const workbookName = decodeURIComponent(queryParams.get("workbookName") || "");

        const url = workbookContentUrl + "/" + viewContentUrl;

        const jwt = Cookies.get("tableauJwtElev");

        const getJwt = async (username: string) => {
            const res = await northstarClient.post(
                `${GATSBY_API_URL}/api/tableau/get/jwt`,
                {
                    username
                }
            );

            Cookies.set("tableauJwtElev", res, { expires: 1 / 192 });
            setJwt(res);
        }

        if (!jwt) {
            const username = Cookies.get("tableauUserElev");
            getJwt(username);
        }
        else setJwt(jwt);

        setViewUrl(url);
        setViewName(viewName);
        setWorkbookName(workbookName);

      }, []);

   return (
    <>
        <NorthstarPageLayout title={"View - " + workbookName}>
            { jwt === "" ? <></> :
            <tableau-viz id='tableau-viz'
                src={`https://prod-useast-a.online.tableau.com/t/bamelevate/views/${viewUrl}`}
                toolbar='hidden'
                token = {jwt}
            ></tableau-viz>
            }
        </NorthstarPageLayout>
</>
   )
}

export default TableauView